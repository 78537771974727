/* eslint-disable import/no-unused-modules */
import ErrorCode from './error-code';
import ErrorCodeMap from './error-code-map';

class LavizorError extends Error {
    code;
    // Can be shown in sentry with inner stack trace
    // https://github.com/getsentry/sentry-javascript/issues/1401#issuecomment-418660333
    innerError;

    constructor(message, code, innerError) {
        super(message);

        this.name = this.constructor.name;
        this.code = code || ErrorCode.UnknownError;

        if (innerError)
            this.innerError = innerError;

        if (typeof Error.captureStackTrace === 'function')
            Error.captureStackTrace(this, LavizorError);
    }
    
    static translateFirestoreError(error , fallback, errorCode) {
        let result = null;

        const mapEntry = ErrorCodeMap[error?.code];

        if (mapEntry)
            result = new LavizorError(mapEntry.message, mapEntry.code || ErrorCode.UnknownError);
        else if (fallback instanceof LavizorError)
            result = fallback;
        else if (typeof fallback  === 'string')
            result = new LavizorError(`${fallback}${error?.code ? ` [${error.code}]` : ''}`, errorCode || ErrorCode.UnknownError, error);
        else 
            result = new LavizorError(`Unknown error: ${error?.code}`, ErrorCode.UnknownError, error);

        if (!result.innerError)
            result.innerError = error;

        return result;
    }
}

export default LavizorError;