/**
 * List of error codes in the Auth module
 *
 */
class ErrorCode {
    UnknownError = 'lavizor/unknown-error';
    InvalidArgumentsError = 'lavizor/invalid-arguments';
    NotPermittedError = 'lavizor/not-permitted';
    MissingOrNotPermittedError = 'lavizor/missing-or-not-permitted';
    DatabaseOperationError = 'lavizor/database-operation';
    NotImplementedError = 'lavizor/not-implemented';
    InvalidOperationError = 'lavizor/invalid-operation';
}

export default ErrorCode;