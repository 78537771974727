import { useReducer } from 'react';

import useMounted from './use-mounted';

export const DefaultData = {
    data: null,
    isExisting: false,
    isForbidden: false,
    isLoading: true
};

export const FirebaseReducerActions = {
    Snapshot: 'onsnapshot',
    Forbidden: 'onforbidden'
};

function reducer(state, action) {
    const { type, ...stateUpdates } = action;

    switch (type) {
        case FirebaseReducerActions.Snapshot:
            return {
                ...state,
                ...stateUpdates,
                isLoading: false
            };
        case FirebaseReducerActions.Forbidden:
            return {
                ...state,
                isForbidden: true,
                isLoading: false
            };
        default:
            throw new Error();
    }
}

const useFirebaseReducer = (defaultData) => {
    const [ data, dispatch ] = useReducer(reducer, defaultData);
    const isMounted = useMounted();

    // Wrap dispatch to make sure the component is mounted before calling a re-render
    // Cannot use useCallback here
    const dispatchWrapper = (action) => {
        if (isMounted.current)
            dispatch(action);
    };

    return [
        data,
        // dispatch
        dispatchWrapper
    ];
};

export default useFirebaseReducer;
